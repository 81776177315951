import { detailServices } from '../services/detailServices';
import {
    GET_POOL_INFO, GET_POOL_INFO_SUCCEED,
    GET_POOL_INFO_FAILED, GET_TOKEN_INFO, GET_TOKEN_INFO_SUCCEED, GET_TOKEN_INFO_FAILED,
    GET_HOURLY_INFO, GET_HOURLY_INFO_SUCCEED, GET_HOURLY_INFO_FAILED, GET_ALL_TOKEN_STATUS, GET_ALL_TOKEN_ICONS_SUCCEED, GET_ALL_TOKEN_STATUS_SUCCEED,
    GET_ALL_TOKEN_STATUS_FAILED,GET_TOKEN_PRICE_HISTORY,GET_TOKEN_PRICE_HISTORY_FAILED,GET_TOKEN_PRICE_HISTORY_SUCCEED
} from '../constants';
import { alertActions } from './alertActions';

export const detailActions = {
    getPoolDetails,
    getTokenDetails,
    getHourlyDetails,
    getAllTokenStatus,
    getTokenPriceHistory
}
function getPoolDetails() {
    return dispatch => {
        dispatch(request());
        detailServices.getPoolDetails()
            .then(
                res => {
                    let data = []
                    for (const [key, value] of Object.entries(res.data)) {
                        data.push(value)
                    }
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_POOL_INFO } }
    function success(data) { return { type: GET_POOL_INFO_SUCCEED, data } }
    function failure(error) { return { type: GET_POOL_INFO_FAILED, error } }
}

function getTokenDetails() {
    return dispatch => {
        dispatch(request());
        detailServices.getTokenDetails()
            .then(
                res => {
                    let data = []
                    for (const [key, value] of Object.entries(res.data)) {
                        data.push(value)
                    }
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_TOKEN_INFO } }
    function success(data) { return { type: GET_TOKEN_INFO_SUCCEED, data } }
    function failure(error) { return { type: GET_TOKEN_INFO_FAILED, error } }
}

function getHourlyDetails() {
    return dispatch => {
        dispatch(request());
        detailServices.getHourlyDetails()
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_HOURLY_INFO } }
    function success(data) { return { type: GET_HOURLY_INFO_SUCCEED, data } }
    function failure(error) { return { type: GET_HOURLY_INFO_FAILED, error } }
}

function getAllTokenStatus(token) {
    return dispatch => {
        dispatch(request());
        detailServices.getAllTokenStatus(token)
            .then(
                res => {
                    dispatch(success(res.data));
                    let iconMaps = {}
                    for (let i = 0; i < res.data.length; i++) {
                        let name = res.data[i].token
                        let logo = {}
                        logo.bigLogoUrl = res.data[i].bigLogoUrl
                        logo.smallLogoUrl = res.data[i].smallLogoUrl
                        iconMaps[name] = logo
                    }
                    dispatch(successIcon(iconMaps));
                },
                error => {
                    if (error === 'This username has been used by another account.') {
                        dispatch('add succeed!');
                    } else {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                }
            );
    };

    function request() { return { type: GET_ALL_TOKEN_STATUS } }
    function successIcon(iconMaps) { return { type: GET_ALL_TOKEN_ICONS_SUCCEED, iconMaps } }
    function success(data) { return { type: GET_ALL_TOKEN_STATUS_SUCCEED, data } }
    function failure(message) { return { type: GET_ALL_TOKEN_STATUS_FAILED, message } }
}

function getTokenPriceHistory(payload) {
    return dispatch => {
        dispatch(request());
        detailServices.getTokenPriceHistory(payload)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_TOKEN_PRICE_HISTORY } }
    function success(data) { return { type: GET_TOKEN_PRICE_HISTORY_SUCCEED, data } }
    function failure(error) { return { type: GET_TOKEN_PRICE_HISTORY_FAILED, error } }
}