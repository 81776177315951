export const GET_SUMMARY = 'GET_SUMMARY';
export const GET_SUMMARY_SUCCEED = 'GET_SUMMARY_SUCCEED';
export const GET_SUMMARY_FAILED = 'GET_SUMMARY_FAILED';

export const GET_DAILY_INFO = 'GET_DAILY_INFO';
export const GET_DAILY_INFO_SUCCEED = 'GET_DAILY_INFO_SUCCEED';
export const GET_DAILY_INFO_FAILED = 'GET_DAILY_INFO_FAILED';

export const GET_HOURLY_INFO = 'GET_HOURLY_INFO';
export const GET_HOURLY_INFO_SUCCEED = 'GET_HOURLY_INFO_SUCCEED';
export const GET_HOURLY_INFO_FAILED = 'GET_HOURLY_INFO_FAILED';

export const GET_POOL_INFO = 'GET_POOL_INFO';
export const GET_POOL_INFO_SUCCEED = 'GET_POOL_INFO_SUCCEED';
export const GET_POOL_INFO_FAILED = 'GET_POOL_INFO_FAILED';

export const GET_TOKEN_INFO = 'GET_TOKEN_INFO';
export const GET_TOKEN_INFO_SUCCEED = 'GET_TOKEN_INFO_SUCCEED';
export const GET_TOKEN_INFO_FAILED = 'GET_TOKEN_INFO_FAILED';

export const SUCCESS = 'ALERT_SUCCESS';
export const ERROR = 'ALERT_ERROR';
export const CLEAR = 'ALERT_CLEAR';

export const GET_ALL_TOKEN_STATUS_SUCCEED = 'GET_ALL_TOKEN_STATUS_SUCCEED'
export const GET_ALL_TOKEN_STATUS_FAILED = 'GET_ALL_TOKEN_STATUS_FAILED'
export const GET_ALL_TOKEN_STATUS = 'GET_ALL_TOKEN_ICONS_STATUS,'
export const GET_ALL_TOKEN_ICONS_SUCCEED = 'GET_ALL_TOKEN_ICONS_SUCCEED,'

export const GET_TOKEN_PRICE_HISTORY = 'GET_TOKEN_PRICE_HISTORY';
export const GET_TOKEN_PRICE_HISTORY_SUCCEED = 'GET_TOKEN_PRICE_HISTORY_SUCCEED';
export const GET_TOKEN_PRICE_HISTORY_FAILED = 'GET_TOKEN_PRICE_HISTORY_FAILED';

export const GET_SWAP_TRANSACTIONS = 'GET_SWAP_TRANSACTIONS';
export const GET_SWAP_TRANSACTIONS_SUCCEED = 'GET_SWAP_TRANSACTIONS_SUCCEED';
export const GET_SWAP_TRANSACTIONS_FAILED = 'GET_SWAP_TRANSACTIONS_FAILED';

export const GET_LIQUIDITY_TRANSACTIONS = 'GET_LIQUIDITY_TRANSACTIONS';
export const GET_LIQUIDITY_TRANSACTIONS_SUCCEED = 'GET_LIQUIDITY_TRANSACTIONS_SUCCEED';
export const GET_LIQUIDITY_TRANSACTIONS_FAILED = 'GET_LIQUIDITY_TRANSACTIONS_FAILED';

export const GET_TRADE_TRANSACTIONS = 'GET_TRADE_TRANSACTIONS';
export const GET_TRADE_TRANSACTIONS_SUCCEED = 'GET_TRADE_TRANSACTIONS_SUCCEED';
export const GET_TRADE_TRANSACTIONS_FAILED = 'GET_TRADE_TRANSACTIONS_FAILED';