import * as Url from '../../config/Url'
import { handleResponse } from '../../utils/HandleResponse'

export const summaryServices = {
    getSummaryInfo,
    get24HourInfo
};

async function getSummaryInfo() {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader(payload.token),
        // body: JSON.stringify(payload)
    };
    return fetch(Url.SUMMARY, requestOptions).then(handleResponse);
}

async function get24HourInfo() {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader(payload.token),
        // body: JSON.stringify(payload)
    };
    return fetch(Url.DAYILY_INFO, requestOptions).then(handleResponse);
}
