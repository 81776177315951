import {
    GET_SWAP_TRANSACTIONS,
    GET_SWAP_TRANSACTIONS_SUCCEED,
    GET_SWAP_TRANSACTIONS_FAILED,
    GET_LIQUIDITY_TRANSACTIONS,
    GET_LIQUIDITY_TRANSACTIONS_FAILED,
    GET_LIQUIDITY_TRANSACTIONS_SUCCEED,
    GET_TRADE_TRANSACTIONS,
    GET_TRADE_TRANSACTIONS_SUCCEED,
    GET_TRADE_TRANSACTIONS_FAILED
} from '../constants';

export function trade (state = {
    swapTransactions:{
        list:[],
        page:0,
        size:10,
        isEnd:false,
        total:0
    },
    liquidityTransactions:{
        list:[],
        page:0,
        size:10,
        isEnd:false,
        total:0
    },
    tradeTransactions:[]
}, action) {
    switch (action.type) {
        case GET_SWAP_TRANSACTIONS:
            return {
                ...state,
                loading: true,
                swapTransactions:{
                    list:[],
                    page:0,
                    size:10,
                    isEnd:false,
                    total:0
                }
            }
        case GET_SWAP_TRANSACTIONS_SUCCEED:
            return {
                ...state,
                loading: false,
                swapTransactions: action.data
            }
        case GET_SWAP_TRANSACTIONS_FAILED:
            return {
                ...state,
                loading: false,
                swapTransactionsMsg: action.error
            }
        case GET_LIQUIDITY_TRANSACTIONS:
            return {
                ...state,
                loading: true,
                liquidityTransactions:{
                    list:[],
                    page:0,
                    size:10,
                    isEnd:false,
                    total:0
                }
            }
        case GET_LIQUIDITY_TRANSACTIONS_SUCCEED:
            return {
                ...state,
                loading: false,
                liquidityTransactions: action.data
            }
        case GET_LIQUIDITY_TRANSACTIONS_FAILED:
            return {
                ...state,
                loading: false,
                liquidityTransactionsMsg: action.error
            }

        case GET_TRADE_TRANSACTIONS:
            return {
                ...state,
                loading: true,
                tradeTransactions:[]
            }
        case GET_TRADE_TRANSACTIONS_SUCCEED:
            return {
                ...state,
                loading: false,
                tradeTransactions: action.data
            }
        case GET_TRADE_TRANSACTIONS_FAILED:
            return {
                ...state,
                loading: false,
                tradeTransactionsMsg: action.error
            }
        default:
            return state;
    }

    return state;
};
