import {
    GET_SUMMARY, GET_SUMMARY_SUCCEED, GET_SUMMARY_FAILED, GET_DAILY_INFO, GET_DAILY_INFO_SUCCEED, GET_DAILY_INFO_FAILED
} from '../constants';

export function summary (state = {
    summary: {},
    dailyInfo: {},
    summaryMsg: '',
    dailyInfoMsg: ''
}, action) {
    switch (action.type) {
        case GET_SUMMARY:
            return {
                ...state,
                loading: true
            }
        case GET_SUMMARY_SUCCEED:
            return {
                ...state,
                loading: false,
                summary: action.data
            }
        case GET_SUMMARY_FAILED:
            return {
                ...state,
                loading: false,
                summaryMsg: action.error
            }
        case GET_DAILY_INFO:
            return {
                ...state,
                loading: true
            }
        case GET_DAILY_INFO_SUCCEED:
            return {
                ...state,
                loading: false,
                dailyInfo: action.data
            }
        case GET_DAILY_INFO_FAILED:
            return {
                ...state,
                loading: false,
                dailyInfoMsg: action.error
            }
        default:
            return state;
    }

    return state;
};
