import {
    GET_POOL_INFO, GET_POOL_INFO_SUCCEED,
    GET_POOL_INFO_FAILED, GET_TOKEN_INFO, GET_TOKEN_INFO_SUCCEED, GET_TOKEN_INFO_FAILED,
    GET_HOURLY_INFO, GET_HOURLY_INFO_SUCCEED, GET_HOURLY_INFO_FAILED, GET_ALL_TOKEN_STATUS,
    GET_ALL_TOKEN_ICONS_SUCCEED, GET_ALL_TOKEN_STATUS_SUCCEED, GET_ALL_TOKEN_STATUS_FAILED,
    GET_TOKEN_PRICE_HISTORY,GET_TOKEN_PRICE_HISTORY_SUCCEED,GET_TOKEN_PRICE_HISTORY_FAILED } from '../constants';

export function details (state = {
    poolsInfo: [],
    tokensInfo: [],
    hourlyInfo: [],
    poolsMsg: '',
    tokensMsg: '',
    hourlyMsg: '',
    tokenList: [],
    tokenIcons: {},
    tokenPriceHistory:[]
}, action) {
    switch (action.type) {
        case GET_POOL_INFO:
            return {
                ...state,
                loading: true
            }
        case GET_POOL_INFO_SUCCEED:
            return {
                ...state,
                loading: false,
                poolsInfo: action.data
            }
        case GET_POOL_INFO_FAILED:
            return {
                ...state,
                loading: false,
                poolsMsg: action.error
            }
        case GET_TOKEN_INFO:
            return {
                ...state,
                loading: true
            }
        case GET_TOKEN_INFO_SUCCEED:
            return {
                ...state,
                loading: false,
                tokensInfo: action.data
            }
        case GET_TOKEN_INFO_FAILED:
            return {
                ...state,
                loading: false,
                tokensMsg: action.error
            }
        case GET_HOURLY_INFO:
            return {
                ...state,
                loading: true
            }
        case GET_HOURLY_INFO_SUCCEED:
            return {
                ...state,
                loading: false,
                hourlyInfo: action.data
            }
        case GET_HOURLY_INFO_FAILED:
            return {
                ...state,
                loading: false,
                hourlyMsg: action.error
            }
        case GET_ALL_TOKEN_STATUS:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_TOKEN_STATUS_SUCCEED:
            return {
                ...state,
                tokenList: action.data,
                loading: false
            }
        case GET_ALL_TOKEN_STATUS_FAILED:
            return {
                ...state,
                message: action.message,
                loading: false
            }
        case GET_ALL_TOKEN_ICONS_SUCCEED:
            return {
                ...state,
                tokenIcons: action.iconMaps
            }
        case GET_TOKEN_PRICE_HISTORY:
            return {
                ...state,
                loading: true
            }
        case GET_TOKEN_PRICE_HISTORY_SUCCEED:
            return {
                ...state,
                loading: false,
                tokenPriceHistory: action.data
            }
        case GET_TOKEN_PRICE_HISTORY_FAILED:
            return {
                ...state,
                loading: false,
                tokenPriceHistoryMsg: action.error
            }
        default:
            return state;
    }

    return state;
};
