import {makeStyles, Grid, Typography, Avatar} from '@material-ui/core';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Tokens from "./Tokens";
import { useHistory, useParams } from 'react-router-dom';
import './Tokens.css'
import {useDispatch, useSelector} from "react-redux";
import { detailActions } from "../../redux/actions/detailActions";
import useWindowDimensions from "../../utils/WindowDimensions";
import {isMobile} from "react-device-detect";
import {FormatNumber} from "../../utils/FormatNumber";
import {createContainer, VictoryAxis, VictoryChart, VictoryLabel, VictoryLine, VictoryTooltip,VictoryVoronoiContainer} from "victory";
import {roundingDown} from "../../utils/RoundingDown";
import {getIcons, renderCoinWithCircBG} from "../../utils/RenderIcon";


function TokenDetails({ t, width, height, navBarHeight }) {
    let { token } = useParams();
    const { tokenPriceHistory,tokenIcons, tokensInfo } = useSelector(state => state.details)
    let tokenInfo = tokensInfo.find(item => item.token === token);
    tokenInfo = tokenInfo ? tokenInfo : {allvolume: 0,
        increase: 0,
        liquidity: 0,
        price: 0,
        time: "",
        token: ""};
    const history = useHistory();
    const useStyles = makeStyles({
        font: {
            color: '#FFFFFF',
            fontFamily: "PingFang SC",
            fontSize: isMobile ? 14 : 24,
            fontWeight: 600,
            letterSpacing: 0,
        },
        arrowUp: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '8px solid green',
            marginRight: 15
        },
        arrowDown: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid red',
            marginRight: 15
        },
    });
    const classes = useStyles();
    const dispatch = useDispatch();
    const initApidata = () => {
        dispatch(detailActions.getTokenDetails())
        dispatch(detailActions.getAllTokenStatus())
        dispatch(detailActions.getTokenPriceHistory(token))
    }

    const data = tokenPriceHistory.map(item => {
        return { x: item.createdAt, y: item.price }
    })
    const price = tokenInfo.price
    const dates = tokenInfo.time
    const [hoverPrice, setHoverPrice] = React.useState(-1);
    const [hoverDate, setHoverDate] = React.useState('');
    const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");
    useEffect(() => {
        initApidata()
        return () => {
        }
    }, [])

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start',  minHeight: 1500, }} >
            <div style={{width:'86%'}}>
                <Typography className={classes.font} style={{fontSize:isMobile?10:16}}>
                    <div style={{display:'inline', cursor:'pointer', color:"#8E96A3"}} onClick={()=>{history.push('')}}>{
                        t('Summary')}
                    </div>
                    {' > '}
                    <div style={{display:'inline', cursor:'pointer'}} onClick={()=>{history.push('/tokens')}}>{
                        t('Tokens')}
                    </div>
                    {' > ' +token}
                </Typography>
                <div style={{display:'flex', alignItems:'center', marginTop:36}}>
                    <div key={'SAP'} id={'SAP'} style={{ display: 'flex', backgroundColor: 'white', borderRadius: 48 / 2, height: 24, width: 24, borderWidth: 3, borderColor: '#101B66', alignContent: 'center', alignItems: 'center', justifyContent: 'center', }}>
                        <Avatar src={getIcons(token, tokenIcons, true)} style={{ alignSelf: 'center', height: 16, width: 16, resizeMode: 'contain' }} />
                    </div>
                    <Typography className={classes.font} style={{marginLeft:10}}>
                        {token}
                    </Typography>
                </div>
                <div className={classes.font} style={{marginTop:12, display:"flex", fontSize:isMobile ? 32:48, wordBreak:'break-word'}}>
                    <div>
                        ${roundingDown(tokenInfo.price, 8)}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft:24 }}>
                        {
                            parseFloat(tokenInfo.increase) > 0 ?
                                <div className={classes.arrowUp}></div> : <div className={classes.arrowDown}></div>
                        }
                        <Typography style={{ color: tokenInfo.increase > 0 ? 'green' : 'red', wordBreak:'normal' }}>{`${roundingDown(tokenInfo.increase, 2)}%`}</Typography>
                    </div>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <div style={{
                            background: 'linear-gradient(#171d21,#0f0e14)',
                            borderRadius: 20,
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: isMobile ? 12 : 50,
                            marginBottom: 0,
                            height:'100%',
                            border: '2px solid #1E1D22'
                        }}
                        >
                            <Typography className={classes.font} style={{
                                fontSize: isMobile ? 10 : 16,
                                color: '#8E96A3',
                                paddingLeft: 24,
                                paddingTop: isMobile ? 24 : 40,
                            }}>{t("tableLiquidity")}
                            </Typography>
                            <Typography className={classes.font} style={{
                                paddingLeft: 24,
                            }}>${FormatNumber(roundingDown(tokenInfo.liquidity, 2))}
                            </Typography>
                            <Typography className={classes.font} style={{
                                fontSize: isMobile ? 10 : 16,
                                color: '#8E96A3',
                                paddingLeft: 24,
                                paddingTop: isMobile ? 24 : 40,
                            }}>{t('tableDailyUSD')}
                            </Typography>
                            <Typography className={classes.font} style={{
                                paddingLeft: 24,
                            }}>${FormatNumber(roundingDown(tokenInfo.allvolume, 2))}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <div style={{
                            background: 'linear-gradient(#171d21,#0f0e14)',
                            borderRadius: 20,
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: isMobile ? 12 : 50,
                            marginBottom: 0,
                            border: '2px solid #1E1D22',
                            height:"100%"
                        }}
                        >
                            <Typography style={{
                                fontSize: isMobile ? 14 : 24,
                                color: 'white',
                                fontWeight: 'bold',
                                paddingLeft: isMobile ? 24 : 40,
                                paddingTop: isMobile ? 24 : 40,
                            }}>
                                {
                                    t('price') + ` $${hoverPrice >= 0? roundingDown(hoverPrice,8): roundingDown(price,8)}`
                                }
                            </Typography>
                            <Typography style={{
                                fontSize: isMobile ? 10 : 16,
                                color: '#8E96A3',
                                fontWeight: 'bold',
                                paddingLeft: isMobile ? 24 : 40,
                                paddingTop: 5,
                                paddingBottom: isMobile ? 0 : 20
                            }}>
                                {
                                    hoverDate ? hoverDate.slice(0, 16).replace('T',' ')+' (UTC)' : dates ? dates.slice(0, 16)+' (UTC)' : null
                                }
                            </Typography>
                            <VictoryChart
                                height={isMobile ? width * 0.6 : width * 0.3}
                                width={width * 0.7}
                                containerComponent={
                                    <VictoryVoronoiContainer
                                        onActivated={(points)=>{if (points[0]){setHoverDate(points[0].x); setHoverPrice(points[0].y);}}}
                                        labels={({ datum }) => {return`${roundingDown(datum.y, 8)} \n ${datum.x.slice(0,16).replace('T',' ')}`}}
                                    />
                                }
                                events={[{
                                    childName: ["line"],
                                    target: "parent",
                                    eventHandlers: {
                                        onMouseOut: () => {
                                            setHoverPrice(-1);
                                            setHoverDate('');
                                        }
                                    }
                                }]}
                            >
                                <VictoryLine
                                    name="line"
                                    interpolation="monotoneX"
                                    data={data}
                                    style={{
                                        data: { stroke: "url(#myGradient)" },
                                    }}
                                />
                                <VictoryAxis crossAxis
                                             standalone={false}
                                             tickCount={isMobile ? 4 : 8}
                                    // tickValues={dates}
                                             tickFormat={(t) => `${typeof t === 'string' && t.length > 11 ? isMobile ? t.slice(5, 10) : t.slice(0, 10) : t}`}
                                    // label={"Time slot (UTC)"}
                                             style={{
                                                 axisLabel: { fontSize: 15, padding: 20 },
                                                 axis: { stroke: "none" }

                                             }}
                                             axisLabelComponent={
                                                 <VictoryLabel
                                                     dy={1}
                                                     dx={10}
                                                     angle={0}
                                                     style={{
                                                         fill: "#a6a7aa",
                                                         fontSize: 10,
                                                         // fontFamily: "'Courier New', Courier, monospace"
                                                     }}
                                                 />
                                             }
                                             tickLabelComponent={
                                                 <VictoryLabel
                                                     style={{
                                                         fill: "#545454",
                                                         fontSize: isMobile ? 10 :15 ,
                                                         // fontFamily: "'Courier New', Courier, monospace"
                                                     }}
                                                 />
                                             }
                                />
                                {/*Y_Axis*/}
                                {/*<VictoryAxis*/}
                                {/*    dependentAxis crossAxis*/}
                                {/*    fixLabelOverlap*/}
                                {/*    style= {{*/}
                                {/*        axisLabel: {fontSize: 10, padding: 20},*/}
                                {/*        axis: {stroke: "none"}*/}
                                {/*    }}*/}
                                {/*    tickCount={10}*/}
                                {/*    axisLabelComponent={*/}
                                {/*        <VictoryLabel*/}
                                {/*            dy={-50}*/}
                                {/*            dx={10}*/}
                                {/*            angle={0}*/}
                                {/*            style={{*/}
                                {/*                fill: "#a6a7aa",*/}
                                {/*                fontSize: 10,*/}
                                {/*                fontWeight: "lighter",*/}
                                {/*                fontFamily: "'Courier New', Courier, monospace"*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*    tickLabelComponent={*/}
                                {/*        <VictoryLabel*/}
                                {/*            style={{*/}
                                {/*                fill: "#a6a7aa",*/}
                                {/*                fontSize: 9,*/}
                                {/*                fontFamily: "'Courier New', Courier, monospace"*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*/>*/}
                                <svg>
                                    <defs>
                                        <linearGradient id="myGradient"
                                                        x1="0%" y1="0%" x2="100%" y2="0%"
                                                        gradientUnits='userSpaceOnUse'
                                        >
                                            <stop offset="0%" stopColor="#47dca8" />
                                            <stop offset="25%" stopColor="#41cab8" />
                                            <stop offset="50%" stopColor="#49a6c5" />
                                            <stop offset="75%" stopColor="#5886cf" />
                                            <stop offset="100%" stopColor="#4c60a7" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </VictoryChart>
                        </div>
                    </Grid>
                </Grid>
            </div>

        </div>

    )
}

export default withTranslation()(TokenDetails);
