import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Tokens from "./Tokens";
import './Tokens.css'
import { useDispatch } from "react-redux";
import { detailActions } from "../../redux/actions/detailActions";
import useWindowDimensions from "../../utils/WindowDimensions";


function TokenWrapper({ t, width, height, navBarHeight }) {

    const useStyles = makeStyles({
        table: {
            minWidth: 700,
        },
    });

    const dispatch = useDispatch();
    const initApidata = () => {
        dispatch(detailActions.getTokenDetails())
        dispatch(detailActions.getAllTokenStatus())
    }

    useEffect(() => {
        initApidata()
        return () => {
        }
    }, [])

    return (
        <div style={{
            display: 'flex',
            width: width,
            // height: height - navBarHeight,
            minHeight: 1500,
            alignItems: 'flex-start',
            justifyContent: 'center',
            // background: 'linear-gradient(135deg, #1b1523, #0f0e14)'
            // flexDirection:'column'
        }}>
            <Tokens width={width * 0.8} showPage={true} rowCount={10} />
        </div>
    )
}

export default withTranslation()(TokenWrapper);
