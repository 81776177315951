import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { withTranslation } from 'react-i18next';
import { renderCoinWithCircBG } from "../../utils/RenderIcon";
import { FormatNumber } from "../../utils/FormatNumber";
import { roundingDown } from "../../utils/RoundingDown";
import { isMobile } from "react-device-detect";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import './Pools.css'
import { move } from "../../utils/Move";

function Pools({ t, width, showPage, rowCount }) {
    const StyledTableCell = withStyles((theme) => ({
        root: {
            borderBottom: 'none',
            // textAlign: 'center'
        },
        head: {
            backgroundColor: 'transparent',
            color: '#38404D',
            borderBottom: 'solid thin #29353f',
            borderWidth: 1.6,
            fontSize: 16,
            padding: '35px 30px',
            paddingLeft: 80
        },
        body: {
            // background:'red',
            fontSize: 16,
            color: 'white',
            padding: '25px 30px',
            paddingLeft: 80
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            backgroundColor: 'transparent'
        },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
            // minWidth: 700,
        },
        pagination: {
            color: 'white',
            // width: '100%',
            border: 'none',
            fontSize: isMobile && showPage ? 10 : 16,
            // alignSelf: ',
            // backgroundColor:"red",
            alignItems: 'flex-start'

        },
        caption: {
            // padding: 8,
            fontSize: isMobile && showPage ? 0 : 16,
        },
        toolbar: {
            "& > p:nth-of-type(2)": {
                fontSize: isMobile && showPage ? 10 : 16,
            }
        },
        arrowUp: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '8px solid green',
            marginRight: 15
        },
        arrowDown: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid red',
            marginRight: 15
        },
    });

    const classes = useStyles();
    const { tokenIcons, poolsInfo } = useSelector(state => state.details)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(rowCount === undefined ?  5 : rowCount);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Reorder data
    let top = poolsInfo.find(item => item.name === 'SAP/USDT')
    let second = poolsInfo.find(item => item.name === 'ETH/USDT')
    let third = poolsInfo.find(item => item.name === 'SAP/SLV')

    poolsInfo.sort((a, b) => {
        if (a.daily_swap_fee >= b.daily_swap_fee)
            return -1
        if (a.daily_swap_fee < b.daily_swap_fee)
            return 1
        return 0
    })

    if (top !== undefined && second !== undefined && third !== undefined) {
        move(poolsInfo, poolsInfo.indexOf(third), 0)
        move(poolsInfo, poolsInfo.indexOf(second), 0)
        move(poolsInfo, poolsInfo.indexOf(top), 0)
    }

    const cellContainer = (item) => {
        return (
            <div style={{ marginLeft: 24, borderRadius: 16, width: 260, marginBottom: isMobile && showPage ? 0 : 24, marginTop: isMobile && showPage ? 24 : 0, height: 110, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#161923', justifyContent: 'space-between' }}>
                <div style={{ width: '91%', display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
                    {renderCoinWithCircBG(item.name, true, 2, tokenIcons)}
                    <div style={{ borderRadius: 24, backgroundColor: parseFloat(item.volume_increase) > 0 ? "#C7FFE8" : '#FFCCC3', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography style={{ fontSize: 10, fontWeight: 'bold', color: item.volume_increase > 0 ? 'green' : 'red', marginLeft: 8, marginRight: 8 }}>
                            {`$${FormatNumber(roundingDown(item.volume, 2))}`}
                        </Typography>
                    </div>
                </div>

                <div style={{ width: '91%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 16 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('tableLiquidity')}
                        </Typography>
                        <Typography style={{ fontSize: 10, fontWeight: 'bold', color: 'white' }}>
                            {`$${FormatNumber(roundingDown(item.liquidity, 2))}`}
                        </Typography>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('tableDailyTxs')}
                        </Typography>
                        <Typography style={{ fontSize: 10, fontWeight: 'bold', color: 'white' }}>
                            {item.amount}
                        </Typography>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('tableDailyFee')}
                        </Typography>
                        <Typography style={{ fontSize: 10, fontWeight: 'bold', color: 'white' }}>
                            {`$${FormatNumber(roundingDown(item.daily_swap_fee, 2))}`}
                        </Typography>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('tableApy')}
                        </Typography>
                        <Typography style={{ fontSize: 10, fontWeight: 'bold', color: 'white' }}>
                            {`${roundingDown(item.trade_apy * 100, 2)}%`}
                        </Typography>
                    </div>
                </div>
            </div >
        );
    }

    return (
        <div style={{
            width: width,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: isMobile ? 12 : 50,
            marginBottom: 50,
        }}>
            {
                isMobile && !showPage ?
                    <div style={{ width: "100%", marginTop: 24, overflow: 'hidden' }}>
                        <ScrollMenu>
                            {
                                (rowsPerPage > 0 && poolsInfo.length > 0
                                    ? poolsInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : poolsInfo
                                ).map((item, index) =>
                                    cellContainer(item)
                                )
                            }
                        </ScrollMenu>
                    </div>
                    :
                    <TableContainer component={Paper} style={{
                        background: 'linear-gradient(180deg, #121523,#0f0e14)',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 20,
                        border: 'solid thin #1E1D22'
                    }}>
                        <Table className={classes.table} aria-label="customized table">
                            {isMobile && showPage ?
                                null
                                :
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{t('tablePair')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tableLiquidity')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tableDailyUSD')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tableDailyTxs')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tableDailyFee')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tableApy')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                            }
                            <TableBody>
                                {(rowsPerPage > 0 && poolsInfo.length > 0
                                    ? poolsInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : poolsInfo
                                ).map((row, index) => (

                                    isMobile && showPage ?
                                        cellContainer(row)

                                        :
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                {renderCoinWithCircBG(row.name, true, 2, tokenIcons)}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{`$${roundingDown(row.liquidity, 2)}`}</StyledTableCell>
                                            <StyledTableCell align="left">
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    {
                                                        parseFloat(row.volume_increase) > 0 ?
                                                            <div className={classes.arrowUp}></div> : <div className={classes.arrowDown}></div>
                                                    }
                                                    <Typography style={{ color: row.volume_increase > 0 ? 'green' : 'red' }}>{`$${FormatNumber(roundingDown(row.volume, 2))}`}
                                                    </Typography>
                                                </div>

                                            </StyledTableCell>
                                            <StyledTableCell align="left">{row.amount}</StyledTableCell>
                                            <StyledTableCell align="left">{`$${FormatNumber(roundingDown(row.daily_swap_fee, 2))}`}</StyledTableCell>
                                            <StyledTableCell align="left">{`${roundingDown(row.trade_apy * 100, 2)}%`}</StyledTableCell>
                                        </StyledTableRow>
                                ))}
                            </TableBody>
                            {
                                showPage ?
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                count={poolsInfo.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'rows per page' },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                classes={{ root: classes.pagination, caption: classes.caption, toolbar: classes.toolbar }}
                                            />
                                        </TableRow>
                                    </TableFooter> : null
                            }
                        </Table>
                    </TableContainer>
            }
        </div>
    )
}

export default withTranslation()(Pools);
