import { combineReducers } from 'redux';
import { summary } from './summary';
import { details } from './details';
import { alert } from "./alert";
import { trade } from "./trade";

const rootReducer = combineReducers({
    summary,
    details,
    trade,
    alert
});

export default rootReducer;
