import * as Url from '../../config/Url'
import { handleResponse } from '../../utils/HandleResponse'

export const detailServices = {
    getPoolDetails,
    getTokenDetails,
    getHourlyDetails,
    getAllTokenStatus,
    getTokenPriceHistory
};

async function getPoolDetails() {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader(payload.token),
        // body: JSON.stringify(payload)
    };
    return fetch(Url.POOL_INFO, requestOptions).then(handleResponse);
}

async function getTokenDetails() {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader(payload.token),
        // body: JSON.stringify(payload)
    };
    return fetch(Url.TOKEN_INFO, requestOptions).then(handleResponse);
}

async function getHourlyDetails() {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader(payload.token),
        // body: JSON.stringify(payload)
    };
    return fetch(Url.HOURLY_INFO, requestOptions).then(handleResponse);
}

async function getAllTokenStatus(token) {
    const requestOptions = {
        method: 'GET',
    };
    return fetch(Url.GET_ALL_TOKENS_STATUS, requestOptions).then(handleResponse);
}

async function getTokenPriceHistory(token) {
    const requestOptions = {
        method: 'GET',
    };
    return fetch(Url.GET_TOKEN_PRICE_HISTORY+token, requestOptions).then(handleResponse);
}
