import * as Url from '../../config/Url'
import { handleResponse } from '../../utils/HandleResponse'

export const tradeServices = {
    getSwapTransactions,
    getLiquidityTransactions
};

async function getSwapTransactions(payload) {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader(payload.token),
        // body: JSON.stringify(payload)
    };
    return fetch(Url.GET_SWAP_TRANSACTIONS + "current=" + payload.current + "&pageSize=" + payload.pageSize, requestOptions).then(handleResponse);
}

async function getLiquidityTransactions(payload) {
    const requestOptions = {
        method: 'GET',
        // headers: authHeader(payload.token),
        // body: JSON.stringify(payload)
    };
    return fetch(Url.GET_LIQUIDITY_TRANSACTIONS + "current=" + payload.current + "&pageSize=" + payload.pageSize, requestOptions).then(handleResponse);
}
