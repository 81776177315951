import { makeStyles, Button } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';
import {detailActions} from "../../redux/actions/detailActions";
import {useDispatch} from "react-redux";
import Swap from "./Swap";
import Liquidity from "./Liquidity";


function TradeWrapper({ t, width, height, navBarHeight }) {

    const useStyles = makeStyles({
        table: {
            minWidth: 700,
        },
        font:{
            color: '#FFFFFF',
            fontFamily: "PingFang SC",
            fontSize: 16,
            fontWeight: 600,
            letterSpacing: 0,
            textTransform:'none'
        }
    });
    const classes = useStyles();
    const dispatch = useDispatch();
    const [select, setSelect] = useState("swap")
    // const initApidata = () => {
    //     dispatch(detailActions.getPoolDetails())
    //     dispatch(detailActions.getAllTokenStatus())
    // }
    //
    // useEffect(() => {
    //     initApidata()
    //     return () => {
    //     }
    // }, [])

    return (

        <div style={{
            display: 'flex',
            width: width,
            // height: height - navBarHeight,
            minHeight:1500,
            alignItems: 'flex-start',
            justifyContent: 'center',
            // background: 'linear-gradient(135deg, #1b1523, #0f0e14)'
            // flexDirection:'column'
        }}>
            <div >
                <div style={{display:'flex', backgroundColor:"black", width:208, borderRadius: 9, marginBottom:28, marginTop:30}}>
                    <Button className={classes.font}
                            style={{height: 44, width: 111, borderRadius: 9, backgroundColor: select === 'swap' ? '#2E3FBA' : null,}}
                            onClick={()=>{setSelect('swap')}}
                    >
                        {t('tradeSwap')}
                    </Button>
                    <Button className={classes.font}
                            style={{height: 44, width: 111, borderRadius: 9, backgroundColor: select === 'liquidity' ? '#2E3FBA' : null, color:'white'}}
                            onClick={()=>{setSelect('liquidity')}}
                    >
                        {t('tradeLiquidity')}
                    </Button>
                </div>
                {select === 'swap' ? <Swap width={width * 0.8} showPage={true} rowCount={10} /> : <Liquidity width={width * 0.8} showPage={true} rowCount={10} />}
            </div>

        </div>


    )
}

export default withTranslation()(TradeWrapper);
