import { summaryServices } from '../services/summaryServices';
import {
    GET_SUMMARY, GET_SUMMARY_SUCCEED,
    GET_SUMMARY_FAILED, GET_DAILY_INFO, GET_DAILY_INFO_SUCCEED, GET_DAILY_INFO_FAILED
} from '../constants';
import { alertActions } from './alertActions';

export const summaryActions = {
    getSummaryInfo,
    get24HourInfo
}
function getSummaryInfo() {
    return dispatch => {
        dispatch(request());
        summaryServices.getSummaryInfo()
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_SUMMARY } }
    function success(data) { return { type: GET_SUMMARY_SUCCEED, data } }
    function failure(error) { return { type: GET_SUMMARY_FAILED, error } }
}

function get24HourInfo() {
    return dispatch => {
        dispatch(request());
        summaryServices.get24HourInfo()
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_DAILY_INFO } }
    function success(data) { return { type: GET_DAILY_INFO_SUCCEED, data } }
    function failure(error) { return { type: GET_DAILY_INFO_FAILED, error } }
}
