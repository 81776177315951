import unknowCoinIcon from '../images/unknowCoinIcon.png';
import { makeId } from './Random'

import {
    Typography, Avatar
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
export function getIcons(name, map, isSmall) {
    if (Object.keys(map).length !== 0 && map[name] !== undefined) {
        let url = ''
        if (isSmall) {
            url = map[name].smallLogoUrl === undefined || map[name].smallLogoUrl === null ? '' : map[name].smallLogoUrl
        } else {
            url = map[name].bigLogoUrl === undefined || map[name].bigLogoUrl === null ? '' : map[name].bigLogoUrl
        }
        return url === '' ? unknowCoinIcon : url
    } else {
        return unknowCoinIcon
    }
}

export const renderCoinWithCircBG = (coinName, isPairMode, count, allCoinIcons) => {
    var baseNumber = 1
    if (count > 3) {
        baseNumber = 0.8
    }
    if (isPairMode) {
        if (coinName && coinName != '') {
            const coin1 = coinName.split(/[/ ]+/)[0]
            const coin2 = coinName.split(/[/ ]+/)[1]
            return (
                <div key={makeId(10)} style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch', marginLeft: 6, alignItems: "flex-start" }}>
                    <div key={makeId(10)} id={coin1} style={{ display: 'flex', backgroundColor: 'white', borderRadius: 48 / 2, height: isMobile ? 24 : 45, width: isMobile ? 24 : 45, borderWidth: 3, borderColor: '#101B66', alignContent: 'center', alignItems: 'center', justifyContent: 'center', }}>
                        <Avatar src={getIcons(coin1, allCoinIcons, true)} style={{ alignSelf: 'center', height: isMobile ? 16 : 30, width: isMobile ? 16 : 30, resizeMode: 'contain' }} />
                    </div>
                    <div key={makeId(10)} id={coin2} style={{ display: 'flex', backgroundColor: 'white', borderRadius: 48 / 2, height: isMobile ? 24 : 45, width: isMobile ? 24 : 45, borderWidth: 3, borderColor: '#101B66', alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginLeft: -8, }}>
                        <Avatar src={getIcons(coin2, allCoinIcons, true)} style={{ alignSelf: 'center', height: isMobile ? 16 : 30, width: isMobile ? 16 : 30, resizeMode: 'contain' }} />
                    </div>
                    <div key={makeId(10)} id={coin2} style={{ paddingLeft: 10, display: 'flex', alignSelf: 'center' }}>
                        <Typography style={{ fontSize: isMobile ? 12 : 16, fontWeight: 'bold', color: 'white' }}>{coinName}</Typography>
                    </div>
                    <div style={{ width: 16 }} />
                </div>
            )
        }


    } else {
        return (
            <div key={makeId(10)} style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch', marginLeft: 6, alignItems: "flex-start" }}>
                <div key={coinName} id={coinName} style={{ display: 'flex', backgroundColor: 'white', borderRadius: 48 / 2, height: isMobile ? 24 : 45, width: isMobile ? 24 : 45, borderWidth: 3, borderColor: '#101B66', alignContent: 'center', alignItems: 'center', justifyContent: 'center', }}>
                    <Avatar src={getIcons(coinName, allCoinIcons, true)} style={{ alignSelf: 'center', height: isMobile ? 16 : 30, width: isMobile ? 16 : 30, resizeMode: 'contain' }} />
                </div>
                <div key={makeId(10)} id={coinName} style={{ paddingLeft: 10, display: 'flex', alignSelf: 'center' }}>
                    <Typography style={{ fontSize: isMobile ? 12 : 16, fontWeight: 'bold', color: 'white' }}>{coinName}</Typography>
                </div>
            </div>
        )
    }
}
